<template>
  <div>
    <div class="project-search px-10">
      <div class="inner mx-auto pt-4 pb-10">
        <!-- <common-page-header></common-page-header>  -->
        <v-row>
          <v-col align="left">
            <v-btn text color="btPrimary" dark class="px-2 mb-6" @click="$router.back()">
              <v-icon size="32" left>mdi-chevron-left-circle-outline</v-icon>
              <span class="black--text">前のページに戻る</span>
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn
              color="orange darken-2"
              class="white--text"
              depressed
              :to="{ name: 'ProjectEdit' }"
              >新規プロジェクト作成</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="project-search__row mb-3">
          <v-col class="text-h5 blue--text text--darken-4 px-0"
            >プロジェクトをさがす</v-col
          >
        </v-row>
        <project-search-conditions
          class="project-search__row mb-12"
          @runSearch="runSearch"
        ></project-search-conditions>
        <v-row class="project-search__row mb-12">
          <v-col class="text-center text-body">検索結果：<span class="text-h5 mx-1">{{ searchResult }}</span>件</v-col>
        </v-row>
        <v-row class="project-search__row">
          <v-col class="px-0">
            <project-cards
              :value="projectsList"
              class="project-search__row mb-6"
            ></project-cards>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col align="center" class="ma-0 pa-0 mb-12 d-flex justify-center">
            <v-card
              color="transparent"
              tile
              elevation="0"
              :max-width="width"
              class="d-flex justify-end ma-0"
            >
              <v-btn
                v-if="searchResult > 0 && hasMoreRec"
                rounded
                color="primary"
                outlined
                @click="seeMore"
              >
                もっと見る
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-overlay :value="overlay" style="position: fixed;">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import {projects_list_test} from '../store/testData'

import { RepositoryFactory } from "../api/RepositoryFactory";
import CommonPageHeader from "../components/atomic/organisms/CommonPageHeader.vue";
import ProjectSearchConditions from "../components/atomic/organisms/ProjectSearchConditions.vue";
import ProjectCards from "../components/atomic/organisms/ProjectCards.vue";

export default {
  components: {
    CommonPageHeader,
    ProjectSearchConditions,
    ProjectCards,
  },
  data() {
    return {
      absolute: true,
      overlay: false,
      searchResult: 0,
      maxGetCount: 10, //一回の検索実行で取得するプロジェクトの最大件数
      offsetCount: 0,
      hasMoreRec: false,

      projectsList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "searchConditions"]),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 1500;
        case "xl":
          return 1500;
        default:
          return "100%";
      }
    },
  },
  methods: {
    seeMore() {
      //もっと見るボタンからの検索ならばoffsetの件数を指定して検索実行
      this.offsetCount += this.maxGetCount;
      this.runSearch(this.searchConditions, true);
    },

    async runSearch(param, seeMore = false) {
      this.overlay = true;

      if (!seeMore) {
        //検索ボタンからの検索ならばoffsetの件数を初期化
        this.offsetCount = 0;
      }

      param.userId = this.userInfo.userId;
      param.maxGetCount = this.maxGetCount;
      param.offset = this.offsetCount;
      const ProjectRepository = RepositoryFactory.get("project");

      const response = await ProjectRepository.getProjectList(param);
      if (response.data.result && response.data.resultCode === "0000") {
        if (!seeMore) {
          //検索ボタンからの検索
          this.projectsList = response.data.data.projects;
          this.hasMoreRec =
            response.data.data.projects.length >= this.maxGetCount;
        } else {
          //もっと見るボタンからの検索
          let projects = response.data.data.projects;
          projects.forEach((e) => this.projectsList.push(e));
          this.hasMoreRec =
            response.data.data.projects.length >= this.maxGetCount;
        }
        this.searchResult = this.projectsList.length;

        this.overlay = false;
      } else {
        this._sendError(response.data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.project-search {
  background-color: #f2f8ff;

  .inner {
    max-width: 1160px;
  }

  &__row {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
